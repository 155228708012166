import React from 'react'
import { Box, Container, Typography, Link, useTheme } from '@mui/material'

const Footer = () => {
    const theme = useTheme()
    const currentYear = new Date().getFullYear()
    const developerName = 'Eduardo Zaniboni'

    return (
        <Box
            component='footer'
            sx={{
                bgcolor: theme.palette.thridPurple.main,
                color: theme.palette.primaryTextColor.main,
                py: 6,
            }}
        >
            <Container maxWidth='lg'>
                <Typography variant='subtitle1' align='center' component='p' sx={{ fontSize: 13 }}>
                    Binha Zanetti Store © {currentYear} - Todos os direitos reservados.
                </Typography>
                <Typography variant='body2' align='center' sx={{ fontSize: 13 }}>
                    Desenvolvido por{' '}
                    <Link color='inherit' href='https://eduardozaniboni.com' sx={{ fontWeight: 'bold' }}>
                        {developerName}
                    </Link>
                </Typography>
            </Container>
        </Box>
    )
}

export default Footer
