import React from 'react'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

const SocialMediaPage = () => {
    const theme = useTheme()

    const socialMedia = [
        { name: 'WhatsApp', icon: <WhatsAppIcon />, url: 'https://wa.me/+5527993120439' },
        { name: 'Instagram', icon: <InstagramIcon />, url: 'https://www.instagram.com/binhazanettistore/' },
    ]

    return (
        <Box sx={{ textAlign: 'center', p: 5 }}>
            <Typography variant='h4' gutterBottom sx={{ color: theme.palette.secondaryTextColor.main }}>
                Redes Sociais
            </Typography>
            <Box display='flex' justifyContent='center' gap={2}>
                {socialMedia.map((media) => (
                    <IconButton
                        key={media.name}
                        onClick={() => window.open(media.url, '_blank')}
                        sx={{
                            backgroundColor: theme.palette.thridPurple.main,
                            color: theme.palette.primaryTextColor.main,
                            '&:hover': {
                                backgroundColor: theme.palette.secondaryPurple.main,
                            },
                            borderRadius: '50%',
                            width: theme.spacing(8),
                            height: theme.spacing(8),
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {media.icon}
                    </IconButton>
                ))}
            </Box>
        </Box>
    )
}

export default SocialMediaPage
