import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import ImageGallery from '../components/ImageGallery'
import { auth } from '../firebase-config'

const CategoryPage = ({ title, category }) => {
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsAdmin(!!user)
        })

        return () => unsubscribe()
    }, [])

    return (
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant='h4' gutterBottom sx={{ pt: 5, pb: 2 }}>
                {title}
            </Typography>
            <ImageGallery isAdmin={isAdmin} category={category} />
        </Box>
    )
}

export default CategoryPage
