import React, { useState, useEffect } from 'react'
import {
    Toolbar,
    Typography,
    IconButton,
    Drawer,
    List,
    ListItemButton,
    ListItemText,
    Button,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import LoginIcon from '@mui/icons-material/Login'
import { motion, AnimatePresence } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase-config'
import { signOut } from 'firebase/auth'

const Header = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsLoggedIn(!!user)
            if (user) {
                startInactivityTimer()
            }
        })
        return unsubscribe
    }, [])

    const startInactivityTimer = () => {
        const timeout = 45 * 60 * 1000 // 45 minutes
        setTimeout(() => {
            signOut(auth)
        }, timeout)
    }

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen)
    }

    const handleNavigation = (path) => {
        navigate(path)
        if (isMobile) {
            setDrawerOpen(false)
        }
    }

    const menus = isLoggedIn
        ? [
              { name: 'Início', path: '/', icon: null },
              { name: 'Mulheres', path: '/mulher', icon: null },
              { name: 'Meninas', path: '/menina', icon: null },
              { name: 'Homens', path: '/homem', icon: null },
              { name: 'Meninos', path: '/menino', icon: null },
          ]
        : [
              { name: 'Início', path: '/', icon: null },
              { name: 'Mulheres', path: '/mulher', icon: null },
              { name: 'Meninas', path: '/menina', icon: null },
              { name: 'Homens', path: '/homem', icon: null },
              { name: 'Meninos', path: '/menino', icon: null },
              { name: 'Login', path: '/login', icon: <LoginIcon /> },
          ]

    const appBarVariants = {
        hidden: { y: -50, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { when: 'beforeChildren', staggerChildren: 0.1 },
        },
    }

    const itemVariants = {
        hidden: { y: -20, opacity: 0 },
        visible: { y: 0, opacity: 1 },
    }

    return (
        <>
            <AnimatePresence mode='wait'>
                <motion.AppBar
                    position='static'
                    variants={appBarVariants}
                    initial='hidden'
                    animate='visible'
                    exit='visible'
                >
                    <Toolbar sx={{ backgroundColor: theme.palette.thridPurple.main }}>
                        {isMobile && (
                            <IconButton
                                edge='start'
                                aria-label='menu'
                                onClick={handleDrawerToggle}
                                sx={{ color: theme.palette.primaryTextColor.main }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Typography
                            variant='h6'
                            sx={{ flexGrow: 1, mx: 2, color: theme.palette.primaryTextColor.main }}
                            onClick={() => handleNavigation('/')}
                        >
                            Binha Zanetti Store
                        </Typography>
                        {!isMobile &&
                            menus.map((menu, index) => (
                                <motion.div key={menu.name} variants={itemVariants}>
                                    <Button
                                        sx={{ mx: 1, color: theme.palette.primaryTextColor.main }}
                                        onClick={() => handleNavigation(menu.path)}
                                    >
                                        {menu.icon ? menu.icon : <ListItemText primary={menu.name} />}
                                    </Button>
                                </motion.div>
                            ))}
                    </Toolbar>
                </motion.AppBar>
            </AnimatePresence>
            <Drawer
                anchor='left'
                open={drawerOpen}
                onClose={handleDrawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: theme.palette.thridPurple.main,
                        color: theme.palette.primaryTextColor.main,
                    },
                }}
            >
                <List>
                    {menus.map((menu) => (
                        <ListItemButton key={menu.name} onClick={() => handleNavigation(menu.path)} sx={{ px: 5 }}>
                            {menu.icon ? menu.icon : <ListItemText primary={menu.name} />}
                        </ListItemButton>
                    ))}
                </List>
            </Drawer>
        </>
    )
}

export default Header
