import { initializeApp } from 'firebase/app'
import { getAuth } from '@firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: 'AIzaSyAzJnHCE01n6CZKW2LiPdtiDUNsTmoiAT0',
    authDomain: 'binha-zanetti-store.firebaseapp.com',
    projectId: 'binha-zanetti-store',
    storageBucket: 'binha-zanetti-store.appspot.com',
    messagingSenderId: '468320136321',
    appId: '1:468320136321:web:859e7d3d67e132e7863180',
    measurementId: 'G-C7S15G68KH',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const storage = getStorage(app)

export { auth, storage }
