import React, { useEffect } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline, Box } from '@mui/material'
import AppBar from './components/AppBar'
import Banner from './components/Banner'
import CategoryMenu from './components/CategoryMenu'
// import SizeSelector from './components/SizeSelector'
import Footer from './components/Footer'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Routes, Route } from 'react-router-dom'
import CategoryPage from './pages/CategoryPage'
import Login from './components/Login'
import { useNavigate } from 'react-router-dom'
import { auth } from './firebase-config'
import { signOut } from 'firebase/auth'
import SocialMediaPage from './pages/SocialMediaPage'

const theme = createTheme({
    palette: {
        primaryPurple: {
            main: '#AFA2FF',
        },
        secondaryPurple: {
            main: '#8075FF',
        },
        thridPurple: {
            main: '#10002b',
        },
        primaryTextColor: {
            main: '#ffd6ff',
        },
        secondaryTextColor: {
            main: '#3c096c',
        },
        white: {
            main: '#F1F1F1',
        },
        black: {
            main: '#0C0C0C',
        },
    },
})

const TIMEOUT = 45 * 60 * 1000 // 45 minutos

function App() {
    const navigate = useNavigate()

    useEffect(() => {
        let timeout

        const resetTimer = async () => {
            clearTimeout(timeout)
            timeout = setTimeout(async () => {
                await signOut(auth)
                navigate('/login')
            }, TIMEOUT)
        }

        window.addEventListener('load', resetTimer)
        document.addEventListener('mousemove', resetTimer)
        document.addEventListener('mousedown', resetTimer)
        document.addEventListener('touchstart', resetTimer)
        document.addEventListener('click', resetTimer)
        document.addEventListener('keydown', resetTimer)

        return () => {
            clearTimeout(timeout)
            window.removeEventListener('load', resetTimer)
            document.removeEventListener('mousemove', resetTimer)
            document.removeEventListener('mousedown', resetTimer)
            document.removeEventListener('touchstart', resetTimer)
            document.removeEventListener('click', resetTimer)
            document.removeEventListener('keydown', resetTimer)
        }
    }, [navigate])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box display='flex' flexDirection='column' minHeight='100vh' sx={{ overflowX: 'hidden' }}>
                <AppBar />
                <Box component='main' flexGrow={1}>
                    <Routes>
                        <Route
                            path='/'
                            element={
                                <>
                                    <Banner />
                                    <CategoryMenu />
                                    {/* <SizeSelector /> */}
                                    <SocialMediaPage />
                                </>
                            }
                        />
                        <Route path='/login' element={<Login />} />
                        <Route path='/mulher' element={<CategoryPage title='Mulher' category='Mulher' />} />
                        <Route path='/homem' element={<CategoryPage title='Homem' category='Homem' />} />
                        <Route path='/menina' element={<CategoryPage title='Menina' category='Menina' />} />
                        <Route path='/menino' element={<CategoryPage title='Menino' category='Menino' />} />
                    </Routes>
                </Box>
                <Footer />
            </Box>
        </ThemeProvider>
    )
}

export default App

