import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Grid,
    Card,
    CardMedia,
    IconButton,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Typography,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { uploadImage, removeImage, listImages } from '../services/imageService'
import { useTheme } from '@mui/material'
import { motion } from 'framer-motion'

const ImageGallery = ({ isAdmin, category }) => {
    const [images, setImages] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [newImage, setNewImage] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(category || 'Mulher')
    const theme = useTheme()

    useEffect(() => {
        fetchImages(category)
    }, [category])

    const fetchImages = async (cat) => {
        const imageUrls = await listImages(cat)
        setImages(imageUrls)
    }

    const handleAddImage = async () => {
        if (newImage && selectedCategory) {
            await uploadImage(newImage, selectedCategory)
            setOpenDialog(false)
            setNewImage(null)
            fetchImages(selectedCategory)
        }
    }

    const handleRemoveImage = async (fileName) => {
        await removeImage(fileName, category)

        setImages(images.filter((image) => image.name !== fileName))
    }

    const categories = ['Mulher', 'Homem', 'Menina', 'Menino']

    return (
        <Box px={5}>
            {isAdmin && (
                <Button
                    startIcon={<AddCircleIcon />}
                    onClick={() => setOpenDialog(true)}
                    sx={{
                        marginBottom: 2,
                        backgroundColor: theme.palette.primaryPurple.main,
                        color: theme.palette.white.main,
                        ':hover': {
                            backgroundColor: theme.palette.secondaryPurple.main,
                        },
                    }}
                >
                    <Typography>Adicionar imagem</Typography>
                </Button>
            )}
            <Grid container px={5} pb={5}>
                {images.map((image) => (
                    <Grid item xs={12} sm={6} md={4} key={image.fileName} sx={{ p: 2 }}>
                        <motion.div
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Card sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                <CardMedia
                                    component='img'
                                    image={image.url}
                                    alt={image.fileName}
                                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                                {isAdmin && (
                                    <IconButton
                                        onClick={() => handleRemoveImage(image.name)}
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            color: theme.palette.error.main,
                                        }}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                )}
                            </Card>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Adicione uma nova imagem</DialogTitle>
                <DialogContent>
                    <TextField
                        type='file'
                        fullWidth
                        onChange={(e) => setNewImage(e.target.files[0])}
                        sx={{
                            my: 2,
                            backgroundColor: theme.palette.white.main,
                            '&:hover': {
                                borderColor: theme.palette.primaryPurple.main,
                            },
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primaryPurple.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.secondaryPurple.main,
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: theme.palette.primaryPurple.main,
                                '&.Mui-focused': {
                                    color: theme.palette.secondaryPurple.main,
                                },
                            },
                        }}
                    />
                    <TextField
                        select
                        label='Categoria'
                        value={selectedCategory}
                        fullWidth
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        sx={{
                            my: 2,
                            backgroundColor: theme.palette.white.main,
                            '&:hover': {
                                borderColor: theme.palette.primaryPurple.main,
                            },
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primaryPurple.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.secondaryPurple.main,
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: theme.palette.primaryPurple.main,
                                '&.Mui-focused': {
                                    color: theme.palette.secondaryPurple.main,
                                },
                            },
                        }}
                    >
                        {categories.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleAddImage}
                        sx={{
                            bgcolor: theme.palette.thridPurple.main,
                            color: theme.palette.primaryTextColor.main,
                            ':hover': { bgcolor: theme.palette.primaryPurple.main },
                        }}
                    >
                        Carregar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ImageGallery
