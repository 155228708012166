import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Card, CardMedia, CardActionArea, Box, Typography, useTheme } from '@mui/material'

const categories = [
    { title: 'Mulher', imageUrl: '/img/mulher.png', path: '/mulher' },
    { title: 'Homem', imageUrl: '/img/homem.png', path: '/homem' },
    { title: 'Menina', imageUrl: '/img/menina.png', path: '/menina' },
    { title: 'Menino', imageUrl: '/img/menino.png', path: '/menino' },
]

const CategoryMenu = () => {
    const navigate = useNavigate()
    const theme = useTheme()

    const handleCategoryClick = (path) => {
        navigate(path)
    }
    return (
        <Box m={1}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant='h4' gutterBottom sx={{ py: 5, color: theme.palette.secondaryTextColor.main }}>
                    Categorias
                </Typography>
            </Box>
            <Grid container spacing={2} sx={{ px: 1 }}>
                {categories.map((category) => (
                    <Grid item xs={12} sm={6} md={6} key={category.title}>
                        <Card>
                            <CardActionArea onClick={() => handleCategoryClick(category.path)}>
                                <CardMedia
                                    component='img'
                                    image={category.imageUrl}
                                    title={category.title}
                                    style={{
                                        height: '50%',
                                        objectFit: 'contain',
                                        padding: '5%',
                                    }}
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default CategoryMenu
