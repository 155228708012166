import { ref, uploadBytes, deleteObject, listAll, getDownloadURL } from 'firebase/storage'
import { storage } from '../firebase-config'

const uploadImage = async (file, category) => {
    const storageRef = ref(storage, `images/${category}/${file.name}`)
    await uploadBytes(storageRef, file)
}

const removeImage = async (fileName, category) => {
    const fileRef = ref(storage, `images/${category}/${fileName}`)
    await deleteObject(fileRef)
}

const listImages = async (category) => {
    const imagesRef = ref(storage, `images/${category}/`)
    const imageFiles = await listAll(imagesRef)
    const imageUrls = await Promise.all(
        imageFiles.items.map(async (item) => {
            const url = await getDownloadURL(item)
            return { url, name: item.name }
        })
    )
    return imageUrls
}

export { uploadImage, removeImage, listImages }
