import React, { useState } from 'react'
import { Box, TextField, Button, Container, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase-config'

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()
    const theme = useTheme()

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password)
            navigate('/')
        } catch (error) {
            console.error('Erro no login:', error)
            alert('Falha no login: ' + error.message)
        }
    }

    return (
        <Container component='main' maxWidth='xs'>
            <Box
                sx={{
                    marginTop: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.palette.thridPurple.main,
                    padding: theme.spacing(3),
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[5],
                    color: theme.palette.white.main,
                }}
            >
                <Typography component='h1' variant='h5' sx={{ color: theme.palette.primaryTextColor.main }}>
                    Conecte-se
                </Typography>
                <TextField
                    variant='filled'
                    margin='normal'
                    required
                    fullWidth
                    label='Email'
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                        backgroundColor: theme.palette.white.main,
                        borderRadius: 1,
                        '& .MuiFilledInput-root': {
                            '&:after': {
                                borderBottomColor: theme.palette.secondaryPurple.main,
                            },
                            '&:hover': {
                                backgroundColor: theme.palette.white.main,
                            },
                        },
                        '& label.Mui-focused': {
                            color: theme.palette.secondaryPurple.main,
                        },
                    }}
                />
                <TextField
                    variant='filled'
                    margin='normal'
                    required
                    fullWidth
                    label='Senha'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{
                        backgroundColor: theme.palette.white.main,
                        borderRadius: 1,
                        transition: 'background-color 300ms linear, border-color 300ms linear',
                        '& .MuiFilledInput-root': {
                            '&:after': {
                                borderBottomColor: theme.palette.secondaryPurple.main,
                            },
                            '&:hover': {
                                backgroundColor: theme.palette.white.main,
                            },
                        },
                        '& label.Mui-focused': {
                            color: theme.palette.secondaryPurple.main,
                        },
                    }}
                />
                <Button
                    fullWidth
                    variant='contained'
                    onClick={handleLogin}
                    sx={{
                        mt: 3,
                        mb: 2,
                        bgcolor: theme.palette.primaryPurple.main,
                        color: theme.palette.secondaryTextColor.main,
                        transition: 'background-color 300ms linear, color 300ms linear',
                        ':hover': {
                            bgcolor: theme.palette.secondaryPurple.main,
                            color: theme.palette.primaryTextColor.main,
                        },
                    }}
                >
                    Entrar
                </Button>
            </Box>
        </Container>
    )
}

export default Login
