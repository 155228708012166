import React from 'react'
import Slider from 'react-slick'
import { Paper } from '@mui/material'

const Banner = () => {
    const banners = ['/img/banner1.png', '/img/banner2.png'] // Array com os caminhos das imagens

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    }

    return (
        <Slider {...settings}>
            {banners.map((banner, index) => (
                <Paper key={index} style={{ position: 'relative', height: 200 }}>
                    <img src={banner} alt={`Banner ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                </Paper>
            ))}
        </Slider>
    )
}

export default Banner
